<template>
	<div>
		<ContentHeader title="User &amp; Hak Akses" url="/user-dan-hak-akses" subTitle="Tambah User &amp; Hak Akses" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-lg-2"></div>
							<div class="col-lg-8">
								<div class="bs-stepper">
								<div class="bs-stepper-header" role="tablist">
									<!-- your steps here -->
									<div class="step" v-bind:class="sesi == 'sesi_user' ? 'active' : ''">
									<button type="button" class="step-trigger" role="tab" aria-controls="logins-part" id="logins-part-trigger">
										<span class="bs-stepper-circle">1</span>
										<span class="bs-stepper-label">Data User</span>
									</button>
									</div>
									<div class="line"></div>
									<div class="step" v-bind:class="sesi == 'sesi_hak_akses' ? 'active' : ''">
									<button type="button" class="step-trigger" role="tab" aria-controls="information-part" id="information-part-trigger">
										<span class="bs-stepper-circle">2</span>
										<span class="bs-stepper-label">Hak Akses User</span>
									</button>
									</div>
								</div>
								<div class="bs-stepper-content">
									<!-- your steps content here -->
									<div id="logins-part" class="content" role="tabpanel" aria-labelledby="logins-part-trigger"></div>
									<div id="information-part" class="content" role="tabpanel" aria-labelledby="information-part-trigger"></div>
								</div>
								</div>
							</div>
							<div class="col-lg2"></div>
						</div>
						
						<!-- DATA USER -->
						<div class="row" v-if="sesi == 'sesi_user'">
							<div class="col-lg-2"></div>
							<div class="col-lg-8">
								<form autocomplete="off" @submit.prevent="ubahSesi('sesi_hak_akses')">
									<div class="form-group">
									<label>Pilih Klinik <span class="text-danger">*</span></label>
									<multiselect 
										v-model="form.klinik"
										deselect-label="Can't remove this value"
										track-by="label"
										label="label"
										placeholder=""
										:options="klinik"
										:allow-empty="false"
										@update:model-value="pilih_klinik"
										>
										</multiselect>
									</div>
									<div class="form-group" v-if="toggle_cabang_input">
									<label>Pilih Cabang <span class="text-danger">*</span></label>
									<multiselect 
										v-model="form.cabang"
										deselect-label="Can't remove this value"
										track-by="nama"
										label="nama"
										placeholder=""
										:options="cabang"
										:loading="loading_cabang"
										:allow-empty="false"
										@update:model-value="pilih_cabang"
										>
										</multiselect>
									</div>
									<div class="form-group">
									<label>Pilih Karyawan <span class="text-danger">*</span></label>
									<multiselect 
										v-model="form.karyawan"
										deselect-label="Can't remove this value"
										track-by="nama_lengkap"
										label="nama_lengkap"
										placeholder=""
										:options="karyawan"
										:loading="loading_karyawan"
										:allow-empty="false"
										>
										</multiselect>
									</div>
									<div class="form-group">
									<label for="nama">Nama Lengkap</label>
									<input type="text" class="form-control" disabled="" v-model="form.karyawan.nama_lengkap">
									</div>
									<div class="form-group">
									<label >Level Jabatan</label>
									<input type="text" class="form-control" disabled="" v-model="form.karyawan.level_jabatan">
									</div>
									<div class="form-group">
									<label >Jabatan Kerja</label>
									<input type="text" class="form-control" disabled="" v-model="form.karyawan.jabatan_kerja">
									</div>
									<div class="form-group">
									<label >Email</label>
									<input type="text" class="form-control" disabled="" v-model="form.karyawan.email">
									</div>
									<div class="form-group">
									<label >Password <span class="text-danger">*</span></label>
									<input type="text" class="form-control" v-model="form.password" required="">
									</div>
									<button type="submit" class="btn btn-primary d-flex align-items-center mt-4" >
										<div>Selanjutnya</div>	
									</button>
								</form>
							</div>
							<div class="col-lg-2"></div>
						</div>
						<!-- END DATA USER -->

						<!-- HAK AKSES -->
						<div class="row" v-if="sesi == 'sesi_hak_akses'">
							<div class="col-lg-1"></div>
							<div class="col-lg-10">
								<form @submit.prevent="prosesData">
									<div class="row">
										<div class="col">
											<div class="icheck-primary">
												<input type="checkbox">
												<h5>Dashboard</h5>
											</div>
											<div class="form-group clearfix ml-2" v-for="(menu, index) in hak_akses.filter((a) => a.menu_id == 1)" :key="index">
												<div class="icheck-primary d-inline">
													<input type="checkbox" :id="menu.nama" v-model="menu.status">
													<label :for="menu.nama" class="text-sm">
														{{ menu.deskripsi }}
													</label>
												</div>
											</div>
										</div>
										<div class="col"></div>
										<div class="col"></div>
									</div>
									<div class="row">
										<div class="col">
											<h5>Master Data</h5>
											<div class="form-group clearfix ml-2" v-for="(menu, index) in hak_akses.filter((a) => a.menu_id == 2)" :key="index">
												<div class="icheck-primary d-inline">
													<input type="checkbox" :id="menu.nama" v-model="menu.status">
													<label :for="menu.nama" class="text-sm">
														{{ menu.deskripsi }}
													</label>
												</div>
											</div>
										</div>
										<div class="col">
											<h5>Rekam Medis</h5>
											<div class="form-group clearfix ml-2" v-for="(menu, index) in hak_akses.filter((a) => a.menu_id == 3)" :key="index">
												<div class="icheck-primary d-inline">
													<input type="checkbox" :id="menu.nama" v-model="menu.status">
													<label :for="menu.nama" class="text-sm">
														{{ menu.deskripsi }}
													</label>
												</div>
											</div>
										</div>
										<div class="col">
											<h5>Farmasi / Inventori</h5>
											<div class="form-group clearfix ml-2" v-for="(menu, index) in hak_akses.filter((a) => a.menu_id == 4)" :key="index">
												<div class="icheck-primary d-inline">
													<input type="checkbox" :id="menu.nama" v-model="menu.status">
													<label :for="menu.nama" class="text-sm">
														{{ menu.deskripsi }}
													</label>
												</div>
											</div>
										</div>
									</div>
									<div class="row mt-2">
										<div class="col">
											<h5>Akuntansi</h5>
											<div class="form-group clearfix ml-2" v-for="(menu, index) in hak_akses.filter((a) => a.menu_id == 5)" :key="index">
												<div class="icheck-primary d-inline">
													<input type="checkbox" :id="menu.nama" v-model="menu.status">
													<label :for="menu.nama" class="text-sm">
														{{ menu.deskripsi }}
													</label>
												</div>
											</div>
										</div>
										<div class="col">
											<h5>HRD</h5>
											<div class="form-group clearfix ml-2" v-for="(menu, index) in hak_akses.filter((a) => a.menu_id == 6)" :key="index">
												<div class="icheck-primary d-inline">
													<input type="checkbox" :id="menu.nama" v-model="menu.status">
													<label :for="menu.nama" class="text-sm">
														{{ menu.deskripsi }}
													</label>
												</div>
											</div>
										</div>
										<div class="col">
											<h5>Pelayanan</h5>
											<div class="form-group clearfix ml-2" v-for="(menu, index) in hak_akses.filter((a) => a.menu_id == 7)" :key="index">
												<div class="icheck-primary d-inline">
													<input type="checkbox" :id="menu.nama" v-model="menu.status">
													<label :for="menu.nama" class="text-sm">
														{{ menu.deskripsi }}
													</label>
												</div>
											</div>
										</div>
									</div>	
									<div class="row">
										<div class="col">
											<h5>Laporan-laporan</h5>
											<div class="form-group clearfix ml-2" v-for="(menu, index) in hak_akses.filter((a) => a.menu_id == 8)" :key="index">
												<div class="icheck-primary d-inline">
													<input type="checkbox" :id="menu.nama" v-model="menu.status">
													<label :for="menu.nama" class="text-sm">
														{{ menu.deskripsi }}
													</label>
												</div>
											</div>
										</div>
										<div class="col">
											<h5>Analisis Statistik</h5>
											<div class="form-group clearfix ml-2" v-for="(menu, index) in hak_akses.filter((a) => a.menu_id == 9)" :key="index">
												<div class="icheck-primary d-inline">
													<input type="checkbox" :id="menu.nama" v-model="menu.status">
													<label :for="menu.nama" class="text-sm">
														{{ menu.deskripsi }}
													</label>
												</div>
											</div>
										</div>
										<div class="col">
											<h5>Marketing</h5>
										</div>
									</div>
									<div class="d-flex mt-4">
										<button class="btn btn-primary d-flex align-items-center ml-2" @click="prosesKembali('sesi_user')">
											<div>Kembali</div>	
										</button>
										<button type="submit" class="btn btn-primary d-flex align-items-center ml-2">
											<div>Submit</div>	
											<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
											<span class="sr-only">Loading...</span>
											</div>
										</button>
									</div>
								</form>
							</div>
							<div class="col-lg-1"></div>
						</div>
						
						<!-- END HAK AKSES -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader2'
import Multiselect from '@suadelabs/vue3-multiselect'

import { ref, reactive, computed } from 'vue'
import store from '@/store'
import axios from 'axios'
import Swal from 'sweetalert2'
import router from '@/router'

const user = computed(() => store.getters['auth/user'])
const sesi = ref('sesi_user')
const ubahSesi = (value) => {
	sesi.value = value
}
const prosesKembali = (value) => {
	sesi.value = value
}
// DATA USER
const form = reactive({
	'klinik' : '',
	'cabang' : '',
	'karyawan' : '',
	'nama' : '',
	'jabatan' : '',
	'email' : '',
	'password' : '',
	'hak_akses' : []
})

const klinik = ref([
	{
		'value' : 'pusat',
		'label' : 'Pusat'
	},
	{
		'value' : 'cabang',
		'label' : 'Cabang'
	}
])

const loading = ref(false)

const { cabang, getCabang, loading_cabang } = useCabang(user)

const { karyawan, getKaryawan, loading_karyawan } = useKaryawan(form.cabang)

const toggle_cabang_input = ref(false)

const pilih_klinik = () => {
	karyawan.value = []
	form.karyawan = ''
	if (form.klinik.value == 'cabang') {
		toggle_cabang_input.value = true
		if (cabang.value.length == 0) {
			getCabang()
		}	
	} else {
		getKaryawan(user.value.cabang_id)
		toggle_cabang_input.value = false
	}
}

const pilih_cabang = () => {
	karyawan.value = []
	form.karyawan = ''
	if (form.klinik.value == 'cabang') {
		getKaryawan(form.cabang.id)	
	} else {
		getKaryawan(user.value.cabang_id)
	}
}
// END DATA USER

// HAK AKSES
const prosesData = () => {
	loading.value = true
	form.hak_akses = hak_akses.value.filter((data) => data.status)

	axios.post('api/hak_akses/create', form)
	.then(() => {
		Swal.fire({
			title: 'Berhasil!',
			text: 'Berhasil Menambahkan Data',
			icon: 'success',
			showConfirmButton: false,
			timer: 1500
		})	
		loading.value = false
		router.push('user-dan-hak-akses')
	})
	.catch(() => {
		Swal.fire({
			title: 'Gagal!',
			text: 'Gagal Menambahkan Data',
			icon: 'error',
			confirmButtonText: 'Ok'
		})	
		loading.value = false
	})
}

function useCabang (user) {
	const cabang = ref([])
	const loading_cabang = ref(false)

	const getCabang = async () => {
		loading_cabang.value = true
		let { data } = await axios.get(`api/cabang/getWhereCabang/${user.value.cabang_id}`)
		
		if (data == 'kosong') {
			loading_cabang.value = false
			cabang.value = []
		} else {
			loading_cabang.value = false
			cabang.value = data
		}
	}

	return { cabang, getCabang, loading_cabang }
}

function useKaryawan () {
	const karyawan = ref([])
	const loading_karyawan = ref(false)

	const getKaryawan = async (id) => {
		loading_karyawan.value = true

		let { data } = await axios.get(`api/karyawan/getWhereCabang/${id}`)
		
		if (data == 'kosong') {
			loading_karyawan.value = false
			karyawan.value = []
		} else {
			loading_karyawan.value = false
			karyawan.value = data
		}
	}

	return { karyawan, getKaryawan, loading_karyawan }
}

const hak_akses = ref([
	{
        'menu_id' : 1,
        'sub_menu_id' : 0,
        'nama' : 'pendapatan_harian',
        'deskripsi' : 'Pendapatan Harian',
        'status' : false
    },
    {
        'menu_id' : 1,
        'sub_menu_id' : 0,
        'nama' : 'pengeluaran_harian',
        'deskripsi' : 'Pengeluaran Harian',
        'status' : false
    },
    {
        'menu_id' : 1,
        'sub_menu_id' : 0,
        'nama' : 'kunjungan_pasien',
        'deskripsi' : 'Kunjungan Pasien',
        'status' : false
    },
    {
        'menu_id' : 1,
        'sub_menu_id' : 0,
        'nama' : 'kunjungan_pasien_baru',
        'deskripsi' : 'Kunjungan Pasien Baru',
        'status' : false
    },
    {
        'menu_id' : 1,
        'sub_menu_id' : 0,
        'nama' : 'grafik_pendapatan',
        'deskripsi' : 'Grafik Pendapatan',
        'status' : false
    },
    {
        'menu_id' : 1,
        'sub_menu_id' : 0,
        'nama' : 'grafik_kunjungan',
        'deskripsi' : 'Grafik Kunjungan',
        'status' : false
    },
    {
        'menu_id' : 1,
        'sub_menu_id' : 0,
        'nama' : 'pendapatan_poli',
        'deskripsi' : 'Pendapatan Poli',
        'status' : false
    },    
    {
        'menu_id' : 2,
        'sub_menu_id' : 0,
        'nama' : 'user_hak_akses',
        'deskripsi' : 'User & Hak Akses',
        'status' : false,
    },
    {
        'menu_id' : 2,
        'sub_menu_id' : 0,
        'nama' : 'setting_klinik',
        'deskripsi' : 'Setting Klinik',
        'status' : false,
    },
    {
        'menu_id' : 2,
        'sub_menu_id' : 0,
        'nama' : 'setup_cabang',
        'deskripsi' : 'Setup Cabang',
        'status' : false,
    },
    {
        'menu_id' : 2,
        'sub_menu_id' : 0,
        'nama' : 'data_tindakan',
        'deskripsi' : 'Data Tindakan',
        'status' : false,
    },
    {
        'menu_id' : 2,
        'sub_menu_id' : 0,
        'nama' : 'administrasi_pemeriksaan',
        'deskripsi' : 'Administrasi & Pemeriksaan',
        'status' : false,
    },
    {
        'menu_id' : 2,
        'sub_menu_id' : 0,
        'nama' : 'poli',
        'deskripsi' : 'Poli',
        'status' : false,
    },
    {
        'menu_id' : 2,
        'sub_menu_id' : 0,
        'nama' : 'layanan',
        'deskripsi' : 'Layanan',
        'status' : false,
    },
    {
        'menu_id' : 2,
        'sub_menu_id' : 0,
        'nama' : 'jadwal_shift',
        'deskripsi' : 'Jadwal Shift',
        'status' : false,
    },
    {
        'menu_id' : 2,
        'sub_menu_id' : 0,
        'nama' : 'pergantian_shift',
        'deskripsi' : 'Pergantian Shift',
        'status' : false,
    },
    {
        'menu_id' : 2,
        'sub_menu_id' : 0,
        'nama' : 'laporan_pergantian_shift',
        'deskripsi' : 'Laporan Pergantian Shift',
        'status' : false,
    },    
    {
        'menu_id' : 3,
        'sub_menu_id' : 0,
        'nama' : 'data_pasien',
        'deskripsi' : 'Data Pasien',
        'status' : false,
    },
    {
        'menu_id' : 3,
        'sub_menu_id' : 0,
        'nama' : 'rekam_medis_pasien',
        'deskripsi' : 'Rekam Medis Pasien',
        'status' : false,
    },
    {
        'menu_id' : 3,
        'sub_menu_id' : 0,
        'nama' : 'mapping_wilayah',
        'deskripsi' : 'Mapping Wilayah',
        'status' : false,
    },    
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'data_obat',
        'deskripsi' : 'Data Obat',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'data_percetakan',
        'deskripsi' : 'Data Pemeriksaan',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'data_alat_kesehatan',
        'deskripsi' : 'Data Alat Kesehatan',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'data_detail_produk',
        'deskripsi' : 'Data Detail Produk',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'pembelian_obat',
        'deskripsi' : 'Pembelian Obat',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'pemesanan_obat',
        'deskripsi' : 'Pemesanan Obat',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'penjualan_obat',
        'deskripsi' : 'Penjualan Obat',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'penjualan_langsung',
        'deskripsi' : 'Penjualan Langsung',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'stok_produk',
        'deskripsi' : 'Stok Produk',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'stok_opname',
        'deskripsi' : 'Stok Opname',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'kartu_stok',
        'deskripsi' : 'Kartu Stok',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'data_supplier',
        'deskripsi' : 'Data Supplier',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'data_pelanggan',
        'deskripsi' : 'Data Pelanggan',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'racikan_obat',
        'deskripsi' : 'Racikan Obat',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'lokasi_obat',
        'deskripsi' : 'Lokasi Obat',
        'status' : false,
    },    
    {
        'menu_id' : 5,
        'sub_menu_id' : 0,
        'nama' : 'data_akun_keuangan',
        'deskripsi' : 'Data Akun Keuangan',
        'status' : false,
    },
    {
        'menu_id' : 5,
        'sub_menu_id' : 0,
        'nama' : 'data_jurnal_keuangan',
        'deskripsi' : 'Data Jurnal Keuangan',
        'status' : false,
    },
    {
        'menu_id' : 5,
        'sub_menu_id' : 0,
        'nama' : 'data_jurnal_singkat',
        'deskripsi' : 'Data Jurnal Singkat',
        'status' : false,
    },
    {
        'menu_id' : 5,
        'sub_menu_id' : 0,
        'nama' : 'data_budgeting_akun_keuangan',
        'deskripsi' : 'Data Budgeting Akun Keuangan',
        'status' : false,
    },    
    {
        'menu_id' : 6,
        'sub_menu_id' : 0,
        'nama' : 'data_karyawan',
        'deskripsi' : 'Data Karyawan',
        'status' : false,
    },
    {
        'menu_id' : 6,
        'sub_menu_id' : 0,
        'nama' : 'data_absensi_karyawan',
        'deskripsi' : 'Data Absensi Karyawan',
        'status' : false,
    },    
    {
        'menu_id' : 7,
        'sub_menu_id' : 0,
        'nama' : 'resepsionis',
        'deskripsi' : 'Resepsionis',
        'status' : false,
    },
    {
        'menu_id' : 7,
        'sub_menu_id' : 0,
        'nama' : 'perawatan_dokter',
        'deskripsi' : 'Perawatan Dokter',
        'status' : false,
    },
    {
        'menu_id' : 7,
        'sub_menu_id' : 0,
        'nama' : 'farmasi',
        'deskripsi' : 'Farmasi',
        'status' : false,
    },
    {
        'menu_id' : 7,
        'sub_menu_id' : 0,
        'nama' : 'kasir',
        'deskripsi' : 'Kasir',
        'status' : false,
    },
    {
        'menu_id' : 7,
        'sub_menu_id' : 0,
        'nama' : 'data_transaksi_perawatan',
        'deskripsi' : 'Data Transaksi Perawatan',
        'status' : false,
    },    
    {
        'menu_id' : 8,
        'sub_menu_id' : 0,
        'nama' : 'laporan_pelayanan_klinik',
        'deskripsi' : 'Laporan Pelayana Klinik',
        'status' : false,
    },
    {
        'menu_id' : 8,
        'sub_menu_id' : 0,
        'nama' : 'laporan_rekam_medis_pasien',
        'deskripsi' : 'Laporan Rekam Medis Pasien',
        'status' : false,
    },
    {
        'menu_id' : 8,
        'sub_menu_id' : 0,
        'nama' : 'laporan_farmasi',
        'deskripsi' : 'Laporan Farmasi',
        'status' : false,
    },
    {
        'menu_id' : 8,
        'sub_menu_id' : 0,
        'nama' : 'laporan_akuntansi',
        'deskripsi' : 'Laporan Akuntansi',
        'status' : false,
    },
    {
        'menu_id' : 8,
        'sub_menu_id' : 0,
        'nama' : 'laporan_hrd',
        'deskripsi' : 'Laporan HRD',
        'status' : false,
    },
    {
        'menu_id' : 8,
        'sub_menu_id' : 0,
        'nama' : 'laporan_jasa_medis',
        'deskripsi' : 'Lapran Jasa Medis',
        'status' : false,
    },    
    {
        'menu_id' : 9,
        'sub_menu_id' : 0,
        'nama' : 'traffic',
        'deskripsi' : 'Traffic',
        'status' : false,
    },
    {
        'menu_id' : 9,
        'sub_menu_id' : 0,
        'nama' : 'revenue',
        'deskripsi' : 'Revenue',
        'status' : false,
    },
    {
        'menu_id' : 9,
        'sub_menu_id' : 0,
        'nama' : 'demografi',
        'deskripsi' : 'Demografi',
        'status' : false,
    },
])

</script>

<style type="text/css" src="@/assets/bs-stepper.min.css"></style>